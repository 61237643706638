import React from "react"
import DocumentTitle from "react-document-title"
import Layout from "../components/layout"

const contact = () => {
    return (
        <Layout>
            <DocumentTitle title="Ananya Neogi - Contact">
                <div className="width-container">
                    <div className="contact-container">
                        <p>
                            For work/project related, email me at- ananyaneogi11
                            [AT] gmail [DOT] com
                        </p>
                        <p>
                            Find me on twitter{" "}
                            <a
                                href="https://twitter.com/_ananyaneogi"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                @_ananyaneogi
                            </a>
                        </p>
                    </div>
                </div>
            </DocumentTitle>
        </Layout>
    )
}

export default contact
